import React, { PureComponent, createRef } from 'react';
import { Formik, FormikProps } from 'formik';
import throttle from 'lodash/throttle';
import * as Yup from 'yup';
import { IAuthPayload } from 'types/auth';
import { connect } from 'react-redux';
import { IState as IStoreState } from 'store/reducers';
import { AuthorizeAction } from 'store/actions/user';
import { getAuthorization, getAuthorizationError } from 'store/selectors/user';
import { Link } from 'react-router-dom';
import {
	StyledForm,
	FormFieldContainer,
	FormField,
	FormFieldErrorMessage,
	ActionContainer,
	ForgotPasswordContainer,
	ForgotPasswordLink
} from './styles';
import Button, { BUTTON_TYPE } from 'components/Button';

const initialFormValues: IAuthPayload = { username: '', password: '' };

const validationSchema = Yup.object().shape({
	username: Yup.string()
		.min(3)
		.required(),
	password: Yup.string()
		.min(3)
		.required(),
});

interface IStoreProps {
	loading: ReturnType<typeof getAuthorization>;
	error: ReturnType<typeof getAuthorizationError>;
}

interface IDispatchProps {
	authorize: (payload: typeof initialFormValues) => void;
}

interface IProps extends IStoreProps, IDispatchProps {}

export default connect<IStoreProps, IDispatchProps, {}, IStoreState>(
	state => ({
		loading: getAuthorization(state),
		error: getAuthorizationError(state),
	}),
	dispatch => ({
		authorize: payload => dispatch(new AuthorizeAction(payload)),
	}),
)(
	class SignInForm extends PureComponent<IProps> {
		private formRef = createRef<Formik<IAuthPayload>>();

		private submitForm = throttle(this.props.authorize, 1000);

		componentWillReceiveProps(nextProps: IProps) {
			const isRequestComplete = this.props.loading && !nextProps.loading;

			if (isRequestComplete && this.formRef.current) {
				this.formRef.current.setSubmitting(false);
			}
		}

		render() {
			return (
				<Formik
					initialValues={initialFormValues}
					validationSchema={validationSchema}
					onSubmit={this.submitForm}
					ref={this.formRef}
					render={this.renderScene}
				/>
			);
		}

		private renderScene = ({ isSubmitting }: FormikProps<IAuthPayload>) => (
			<StyledForm>
				<FormFieldContainer>
					<FormField placeholder="Username" name="username" autoComplete="username" />
					<FormFieldErrorMessage name="username" component="p" />
				</FormFieldContainer>

				<FormFieldContainer>
					<FormField
						placeholder="Password"
						name="password"
						type="password"
						autoComplete="current-password"
					/>
					<FormFieldErrorMessage name="password" component="p" />
				</FormFieldContainer>
				<ForgotPasswordContainer>
					<Link to={'/forgotpassword'}>
						<ForgotPasswordLink>forgot password?</ForgotPasswordLink>
					</Link>
				</ForgotPasswordContainer>

				<ActionContainer>
					<Button
						disabled={isSubmitting}
						loading={isSubmitting}
						kind={BUTTON_TYPE.Primary}
						type="submit"
					>
						Sign In
					</Button>
				</ActionContainer>
			</StyledForm>
		);
	},
);
