import logo from 'assets/logo.svg';
import users from 'assets/users.png';
import products from 'assets/cube.png';
import dashboard from 'assets/dashboard.svg';
import hollibluLogo from 'assets/holliblu.png';

import AuthService from 'services/auth';

const workspace = AuthService.getWorkspace() || '';
const workspaceName = workspace.charAt(0).toUpperCase() + workspace.slice(1);

export default [
	{
		name: workspaceName,
		path: '/dashboard',
		img: workspaceName === 'Holliblu' ? hollibluLogo : logo,
		active: true,
	},
	{
		name: 'Dashboard',
		path: '/dashboard',
		img: dashboard,
		active: true,
	},
	{
		name: 'Users',
		path: '/users',
		img: users,
		active: false,
	},
	{
		name: 'Products',
		path: '/product',
		img: products,
		active: false,
	},
	{
		name: 'Groups',
		path: '/subscription',
		img: products,
		active: false,
	},
	{
		name: 'Admins',
		path: '/admins',
		img: products,
		active: false,
	},
	{
		name: 'Workspaces',
		path: '/workspaces',
		img: products,
		active: false,
	},
	{
		name: 'Withdraw Request',
		path: '/withdrawRequest',
		img: products,
		active: false,
	},
];
