import { ReducersMapObject } from 'redux';
import { Actions } from 'store/actions';
import * as settings from 'store/reducers/settings';
import * as router from 'store/reducers/router';
import * as user from 'store/reducers/user';
import * as users from 'store/reducers/users';
import * as summary from 'store/reducers/summary';
import * as stats from 'store/reducers/stats';
import * as coinsSales from 'store/reducers/coinsSales';
import * as productsRevenue from 'store/reducers/productsRevenue';
import * as products from 'store/reducers/product';
import * as groups from 'store/reducers/group';
import * as workspace from 'store/reducers/workspace';
import * as admin from 'store/reducers/admin';
import * as withdrawRequest from 'store/reducers/withdrawRequest';

export interface IState {
	settings: settings.IState;
	router: router.IState;
	user: user.IState;
	users: users.IState;
	summary: summary.IState;
	stats: stats.IState;
	coinsSales: coinsSales.IState;
	productsRevenue: productsRevenue.IState;
	products: products.IState;
	groups: groups.IState;
	workspace: workspace.IState;
	admin: admin.IState;
	withdrawRequest: withdrawRequest.IState;
}

export const state: IState = {
	settings: settings.initialState,
	router: router.initialState,
	user: user.initialState,
	users: users.initialState,
	summary: summary.initialState,
	stats: stats.initialState,
	coinsSales: coinsSales.initialState,
	productsRevenue: productsRevenue.initialState,
	products: products.initialState,
	groups: groups.initialState,
	workspace: workspace.initialState,
	admin: admin.initialState,
	withdrawRequest: withdrawRequest.initialState,
};

export const reducer: ReducersMapObject<IState, Actions> = {
	settings: settings.reducer,
	router: router.reducer,
	user: user.reducer,
	users: users.reducer,
	summary: summary.reducer,
	stats: stats.reducer,
	coinsSales: coinsSales.reducer,
	productsRevenue: productsRevenue.reducer,
	products: products.reducer,
	groups: groups.reducer,
	workspace: workspace.reducer,
	admin: admin.reducer,
	withdrawRequest: withdrawRequest.reducer,
};
